import { useEffect, useState } from "react"
import { useAccount, useReadContract, useWriteContract } from "wagmi"

export default function CounterComp({ contractDetails }) {

    const [contractData, setContractData] = useState({
        cost: 0,
        maxSupplyAMT: 0
    })

    const { data: maxSupply } = useReadContract({
        address: contractDetails.address,
        abi: contractDetails.abi,
        functionName: 'maxSupply'
    })

    useEffect(() => {
        if (maxSupply) {
            setContractData(old => {
                return {
                    ...old,
                    maxSupplyAMT: maxSupply.toString().split('n')[0]
                }
            })
        }
    }, [maxSupply])

    const { data: costData } = useReadContract({
        address: contractDetails.address,
        abi: contractDetails.abi,
        functionName: 'cost'
    })
    useEffect(() => {
        if (costData) {
            setContractData(old => {
                return {
                    ...old,
                    cost: parseFloat(costData.toString().split('n')[0] / (10 ** 18))
                }
            })
        }
    }, [costData])


    return <section id="fun_facts">
        <div className="container">
            <div className="fn_cs_counter_list">
                <ul>
                    <li>
                        <div className="item">
                            <h3 className="fn__gradient_title">
                                <span className="prefix"></span>
                                <span className="">{contractData?.maxSupplyAMT ?? 0}</span>
                                <span className="suffix"></span>
                            </h3>
                            <p>Max Supply</p>
                            <div className="divider"></div>
                        </div>
                    </li>
                    <li>
                        <div className="item">
                            <h3 className="fn__gradient_title">
                                <span className="prefix"></span>
                                <span className="">{contractData?.cost ?? 0}</span>
                                <span className="suffix"></span>
                            </h3>
                            <p>Public Cost (in MATIC)</p>
                            {/* <div className="divider"></div> */}
                        </div>
                    </li>
                    {/* <li>
                        <div className="item">
                            <h3 className="fn__gradient_title">
                                <span className="prefix"></span>
                                <span className="fn_cs_counter" data-from="0" data-to="36" data-speed="2000" data-decimals="0">0</span>
                                <span className="suffix">+</span>
                            </h3>
                            <p>Collaborators</p>
                            <div className="divider"></div>
                        </div>
                    </li> */}
                </ul>
            </div>

        </div>
    </section>
}