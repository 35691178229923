export default function About() {
	return (
		<section id="about">
			<div className="fn_cs_about">
				<div className="left_part lp">
					<div className="img">
						<div className="img_in" data-bg-img="nfts/1.png"><img src="img/1x1.jpg" alt="" /></div>
					</div>
					<div className="bg_overlay bo">
						<div className="bg_color"></div>
						<div className="bg_image" data-bg-img="nfts/5.png"></div>
					</div>
				</div>
				<div className="right_part">
					<div className="right_in">
						<h3 className="fn__maintitle" data-text="ABOUT US">ABOUT US</h3>
						<div className="fn_cs_divider">
							<div className="divider">
								<span></span>
								<span></span>
							</div>
						</div>
						<div className="desc">
							<p>StepEarn Mining is a groundbreaking decentralized
								application that rewards users with cryptocurrency
								for their physical activity, aligning financial incentives
								with health goals. Operating on a self-sustaining
								ecosystem, ensuring stability and growth through
								user engagement.</p>

						</div>
						<a href="https://x.com/StepEarn" className="metaportal_fn_button" target="_blank"><span>Find us On Twitter</span></a>
					</div>
				</div>
			</div>
		</section>
	)
}