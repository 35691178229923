import { ConnectButton } from "@rainbow-me/rainbowkit"
import { useEffect, useState } from "react"
import { useAccount, useReadContract, useWriteContract } from "wagmi"

export default function Minting({contractDetails, mainNFT}) {

    const [nos, setNos] = useState(1)
    const { address } = useAccount()

    /***********
     * READ CONTRACT
     * ********* */

    const [contractData, setContractData] = useState({
        cost: 0
    })
    const { data: costData } = useReadContract({
        address: contractDetails.address,
        abi: contractDetails.abi,
        functionName: 'cost'
    })
    useEffect(() => {
        if (costData) {
            console.log("COST IS", costData.toString().split('n')[0] / (10 ** 18))

            setContractData(old => {
                return {
                    ...old,
                    cost: parseFloat(costData.toString().split('n')[0] / (10 ** 18))
                }
            })
        }
    }, [costData])

    /***********
     * WRITE CONTRACT
     * ********* */

    const { writeContract, data, error, isPending } = useWriteContract()

    useEffect(() => {
        if (data !== undefined) {
            console.log(data)
            alert("Transaction Successful!")
        }
    }, [data])

    useEffect(() => {
        if (error !== null) {
            // alert("Something went wrong!")
            alert(JSON.stringify(error))
        }
    }, [error])

    const convertToBigInt = (num) => {
        return (num * (10 ** 18)).toString()
    }

    const handleMinting = () => {
        try {
            alert("Please wait, your txn will soon come up...")
            writeContract({
                abi: contractDetails.abi,
                address: contractDetails.address,
                functionName: contractDetails.funcName,
                value: convertToBigInt(contractData.cost * nos),
                args: [
                    address,
                    nos
                ],
            })
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className="container">
                <ConnectButton />
                <div className="row">
                    <div className="col-6">
                        <img className="nftImg" src={mainNFT}></img>
                    </div>

                    <div className="col-6">
                    {
                    address
                        ?
                        // MINTING WORK
                        <div className="fn_cs_contact_form">
                            <form className="contact_form" id="contact_form" autoComplete="off">
                                <div className="input_list">
                                    <ul className="nftBNT">
                                        <li>
                                            <label>Nos of NFTs</label>
                                            <input value={nos} onChange={(e) => setNos(e.target.value)} className="form-control" id="name" type="number" placeholder="Nos Of NFT (ex. 1, 5, etc...)" />
                                        </li>
                                        <li className="full">
                                            <div className="counterBox">
                                                <a onClick={() => handleMinting()} id="send_message" style={{ cursor: 'pointer' }}>
                                                    <button className="btn btn-primary" disabled={isPending ?? false}>Mint Now</button>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <br />
                                <div className="returnmessage" data-success="Your query has been received, We will contact you soon."></div>
                                <div className="empty_notice"><span>! Please Fill Required Fields !</span></div>
                            </form>
                        </div>
                        : ""
                }
                    </div>
                </div>
                
            </div>
    )
}