import Header from './Header'
import Home from './Home'
import Collection from './Collection'
import Contact from './Contact'
import About from './About'
import Divider from './Divider'
import React from 'react';
import Footer from './Footer'
import Samples from './Samples'
import Services from './Services'


import {
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";

import { WagmiProvider } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { http, createConfig } from 'wagmi'
import { polygon } from 'wagmi/chains'

import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  rainbowWallet,
  walletConnectWallet,
  injectedWallet,
  metaMaskWallet,
  oneInchWallet,
  tokenPocketWallet,
  trustWallet
} from '@rainbow-me/rainbowkit/wallets';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Suggested',
      wallets: [injectedWallet, trustWallet, metaMaskWallet, oneInchWallet, tokenPocketWallet],
    },
    {
      groupName: 'Others',
      wallets: [rainbowWallet, walletConnectWallet, trustWallet, injectedWallet, metaMaskWallet, oneInchWallet, tokenPocketWallet],
    },
  ],
  {
    appName: 'Step Earn Shoes',
    projectId: '06d308f796385247804646ce9c0e3034',
  }
)

const wagmiConfig = createConfig({
  connectors,
  chains: [polygon],
  transports: {
    [polygon.id]: http("https://go.getblock.io/e778a47da8a84732a95b731bde2606be")
  },
})

export default function App({ Component, pageProps }) {

  const queryClient = new QueryClient()

  return (
    <>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider theme={darkTheme({ width: "100%" })}>

              <React.Fragment>
                <>
                  <div className="metaportal_fn_content">
                    <Header />
                    <Home />
                    <About />

                    {/* 
                  <Divider />
                      <Samples/>
                      <Divider />
                      <Services/> 
                      */}

                    <Divider />
                    <Collection />
                    <Divider />
                    <Contact />
                    <Footer />
                  </div>
                </>
              </React.Fragment>

          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </>
  );
}